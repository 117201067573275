.App {
    height: 100vh;
    width: 100vw;
    margin: 0 auto;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
}

.App .Loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.App .Header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 2;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* This will change when we get theming */
    color: white;
    font-size: 1.5em;
    padding: 10px;
}

.App .Header .BusinessLogo {
    height: 100px;
    margin: 5px;
}

.App .Header .BusinessAddress {
    font-size: 0.5em;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.App .Content {
    width: 100%;
    flex-grow: 20; /* this will make the content expand */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.App .Footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    /* height: 100px; fixed height */

    background-color: #282c34;
    color: white;
}

.App .Footer .BusinessInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.App .Footer .BusinessInfo p {
    margin: 1px;
}

.App .Footer a {
    color: white;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.9em;
}

.App .Footer a.XAPP {
    font-size: 0.6em;
    padding-bottom: 1.5px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (max-width: 640px) {
    .App .Header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .App .Header .BusinessLogo {
        max-height: 50px;
        margin: 1px;
    }

    .App .Header .BusinessAddress {
        font-size: 0.35em;
        padding: 0px;
        margin: 0px;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.Error-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 40px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
