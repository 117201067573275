@keyframes pulse {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(0.75);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

.Loader {
    text-align: center;
    padding: 1em;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Carriage {
    padding: 0;
    display: inline-block;
    width: 144px;
    transform: rotate(-45deg);
}

.Box {
    width: 40px;
    height: 40px;
    border: 2px solid #1aa7dc;
    background-color: #1aa7dc;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-family: sans-serif;
    margin: 2px;
    flex: 0 0 auto;
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.second {
    animation-delay: 0.25s;
}

.third {
    animation-delay: 0.5s;
}

.fourth {
    animation-delay: 0.75s;
}

.spacer {
    visibility: none;
    opacity: 0;
}

.white {
    background-color: transparent;
}
